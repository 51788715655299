<template>
  <div class="w-4/5 mx-auto">
    <div class="mx-4">
      <div class="text-3xl text-left text-body-4 my-6 font-medium">
        OBCHODNÍ PODMÍNKY MALÉŘENECHCI PRO KLIENTY
      </div>
      <div class="text-xl text-left text-body-4 my-2 font-medium">
        <b
          >Společnost JoViRo, s.r.o., se sídlem Petrohradská 1486/6, Vršovice,
          101 00 Praha 10, IČO: 11721669, zapsanou do obchodního rejstříku
          vedeného Městským soudem v Praze pod sp. zn. C 353475 jako
          provozovatel internetového portálu www.maklerenechci.cz vydává tyto
          obchodní podmínky pro jí provozovanou službu umístěnou na webových
          stránkách https://www.maklerenechci.cz (dále jen „Podmínky“ nebo
          „Obchodní podmínky“), přičemž tyto Podmínky tvoří nedílnou součást
          smlouvy uzavírané mezi Klientem vkládajícím svou nemovitost do portálu
          www.maklerenechci.cz (tak, jak je tento pojem definován níže v těchto
          Podmínkách) a společností JoViRo, s.r.o., Petrohradská 1486/6,
          Vršovice, 101 00 Praha 10, IČO: 11721669, zapsanou do obchodního
          rejstříku vedeného Městským soudem v Praze pod sp. zn. C 353475 (dále
          jen „Joviro“). Obsah uzavírané smlouvy se řídí těmito Podmínkami a
          ujednáními sjednanými mezi Klientem a společností Joviro v systému
          MaklereNechci (tak, jak je tento pojem definován níže). Užíváním
          jakékoli služby poskytované společností Joviro, Klient prohlašuje, že
          si tyto Podmínky v celém rozsahu přečetl, porozuměl jim a souhlasí s
          nimi. Systém MaklereNechci nelze používat bez přijetí těchto Podmínek.
          Klient nebo Osoba, která jménem Klienta o přijme tyto Podmínky,
          prohlašuje, že je oprávněn/a tyto Podmínky jménem Klineta přijmout a
          že se Klient bude těmito Podmínkami v celém rozsahu řídit.
        </b>
      </div>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        1. VYMEZENÍ POJMŮ
      </div>
      <p>
        1.1. Není-li v těchto Podmínkách stanoveno jinak, slova s počátečním
        velkým písmenem mají níže uvedený význam:
      </p>
      <li class="p-2">
        1.1.1„MaklereNechci“ je systém, který prostřednictvím webového rozhraní
        nabízí jeho Uživatelům moderní řešení, jehož prostřednictvím mohou
        prodat svépomocí svoje nemovitosti a můžou rovněž získat služby
        specialistů v oblasti home-staging, video, fotografie nebo právo.
      </li>
      <li class="p-2">
        1.1.2 ,,Klienti“ mohou dále spravovat svojí nahranou nemovitost a
        postupne žádat o služby specialisty ve výše uvedených oborech, pro
        potřeby prodeje svojí nemovitosti za co nejvýhodnějších podmínek bez
        použití služeb realitního zprostředkovatele
      </li>
      <li class="p-2">
        1.1.3. „Databází“ se rozumí soubor jakéhokoli obsahu, který je
        systematicky nebo metodicky uspořádaný a jednotlivě dostupný pomocí
        elektronických či jiných prostředků, bez ohledu na způsob jeho
        vyjádření, který vytvořila společnost Joviro ve smyslu zákona č.
        121/2000 Sb., o právu autorském, ve znění pozdějších předpisů (dále jen
        „Autorský zákon“), zejména Databáze profilů Klientů a jimi nabízených
        nemovitostí vytovořená prostřednictvím portálu www.maklerenechci.cz
      </li>
      <li class="p-2">
        1.1.4. „Profilem“ se rozumí profil Klienta v systému MaklereNechci,
        který zahrnuje veškeré informace o Klientovi na základě informací, které
        o sobě Klient poskytl a rovněž veškeré informace o nemovitosti, které
        Klient do systému zadal jako i o službách, které si Klient
        prostřednictvím systému objednal.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        2. PODMÍNKY POSKYTOVÁNÍ SLUŽEB KLIENTŮM
      </div>
      <li class="p-2">
        2.1. Klienti užívají systém MaklereNechci k tomu, aby inzerovali svoje
        nemovitosti, připravili svoje nemovitosti k prodeji a získali podporu od
        třetích stran při prodeji svojí nemovitosti.
      </li>
      <li class="p-2">
        2.2. Klienti mohou užívat systém MaklereNechci pouze k běžným a zákonem
        dovoleným činnostem a pouze pro potřeby inzerce a propagace svojí
        nemovitosti, jako i pro potřeby objednání dodatečných služeb potřebných
        při prodeji nemovitosti. Klient nemá právo pokoušet se získat přístup k
        informacím určeným jiným osobám a užívat je.
      </li>
      <li class="p-2">
        2.3. Klient nese odpovědnost za zákonnost a správnost veškerého obsahu,
        který Klient uloží do systému MaklereNechci. Klient nese odpovědnost za
        veškeré chyby, nesprávné časové a/nebo jiné údaje, které poskytne při
        vytváření Profilu, nebo objednávání dodatečných služeb.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        3. PŘÍSTUP KLIENTA DO SYSTÉMU (VYTVOŘENÍ A ÚPRAVA PROFILU KLIENTA)
      </div>
      <li class="p-2">
        3.1. Klient má k dispozici přístup, který mu poskytuje zejména tyto
        možnosti:
      </li>
      <li class="p-2 pl-4">
        prohlížení svojí nemovitosti a způsobu její inzerce
      </li>
      <li class="p-2 pl-4">
        přístup k dodatečným službám doporučeným při prodeji nemovitosti
      </li>
      <li class="p-2 pl-4">
        po objednání jednotlivé služby přístup ke kalendáři dostupnosti
        jednotlivých služeb
      </li>
      <li class="p-2 pl-4">tvorbu a správu Profilu Klienta</li>
      <li class="p-2 pl-4">další nastavení</li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">4. CENÍK</div>
      <li class="p-2">
        4.1. Klient je oprávněn využívat služby systému MaklereNechci v
        základním režimu bezplatně. V případě, že se Klient dostane do sekce
        systému MaklereNechci, která je přístupná pouze platícím Klientům, bude
        na to upozorněn. Veškeré dodatečné služby u poskytovány třetími stranami
        jsou zpoplatněny dle ceníku uvedeného při dané službě
      </li>
      <li class="p-2">
        4.2. Klient uhradí společnosti Joviro za nadstandardní služby poskytnuté
        společností Joviro cenu ve výši stanovené v ceníku služeb.
      </li>
      <li class="p-2">
        4.3. Všechny ceny uvedené v jakékoliv nabídce nebo ceníku společnosti
        Joviro jsou vždy uvedeny včetně daně z přidané hodnoty (DPH), není-li u
        konkrétní částky výslovně uvedeno jinak.
      </li>
      <li class="p-2">
        4.4. Společnost Joviro může cenu kdykoli dle svého uvážení změnit za
        předpokladu, že na to Klienta předem upozorní. Změna ceny se oznamuje v
        termínech a způsobem jako změna Podmínek. Změna ceny se nevztahuje na
        již objednané a uhrazení služby.
      </li>
      <li class="p-2">
        4.5. Společnost Joviro vyúčtuje Klienti cenu za poskytnuté služby v
        souladu s ceníkem služeb společně s DPH podle příslušných právních
        předpisů vždy před samotnou aktivací jednotlivé služby poskytované
        společností Joviro.
      </li>
      <li class="p-2">
        4.6. Společnost Joviro působí u některých služeb jako zprostředkovatel
        těchto služeb. Při objednání těchto služeb vzniká smluvní vztah přímo
        mezi Klientem a poskytovatelem služby. Seznam poskytovatelů služeb je
        uveden níže.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">4. CENÍK</div>
      <li class="p-2">
        4.1. Klient je oprávněn využívat služby systému Bezmaklere v základním
        režimu bezplatně. V případě, že se Klient dostane do sekce systému
        Bezmaklere, která je přístupná pouze platícím Klientům, bude na to
        upozorněn. Veškeré dodatečné služby u poskytovány třetími stranami jsou
        zpoplatněny dle ceníku uvedeného při dané službě.
      </li>
      <li class="p-2">
        4.2. Klient uhradí společnosti Joviro za nadstandardní služby poskytnuté
        společností Joviro cenu ve výši stanovené v ceníku služeb.
      </li>
      <li class="p-2">
        4.3. Všechny ceny uvedené v jakékoliv nabídce nebo ceníku společnosti
        Joviro jsou vždy uvedeny včetně daně z přidané hodnoty (DPH), není-li u
        konkrétní částky výslovně uvedeno jinak.
      </li>
      <li class="p-2">
        4.4. Společnost Joviro může cenu kdykoli dle svého uvážení změnit za
        předpokladu, že na to Klienta předem upozorní. Změna ceny se oznamuje v
        termínech a způsobem jako změna Podmínek. Změna ceny se nevztahuje na
        již objednané a uhrazení služby.
      </li>
      <li class="p-2">
        4.5. Společnost Joviro vyúčtuje Klienti cenu za poskytnuté služby v
        souladu s ceníkem služeb společně s DPH podle příslušných právních
        předpisů vždy před samotnou aktivací jednotlivé služby poskytované
        společností Joviro.
      </li>
      <li class="p-2">
        4.6. Společnost Joviro působí u některých služeb jako zprostředkovatel
        těchto služeb. Při objednání těchto služeb vzniká smluvní vztah přímo
        mezi Klientem a poskytovatelem služby. Seznam poskytovatelů služeb je
        uveden níže.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        5. OSOBNÍ ÚDAJE
      </div>
      <li class="p-2">
        5.1. Společnost Joviro jedná jako zpracovatel údajů Klienta pouze v
        souvislosti s informacemi poskytnutými v Profilu Klienta, jehož osobní
        údaje byly uloženy do systému Bezmaklere.
      </li>
      <li class="p-2">
        5.2. Práva a povinnosti společnosti Joviro a Klienta, co se týče
        zpracování osobních údajů se řídí Zásadami ochrany osobních údajů
        dostupnými na stránce www.maklerenechci.cz
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        6. PRÁVA A OMEZENÍ
      </div>
      <li class="p-2">
        6.1. Společnost Joviro je pořizovatelem Databáze a zachovává si veškerá
        práva pořizovatele databáze v souladu s ustanovením § 89 a násl.
        Autorského zákona.
      </li>
      <li class="p-2">
        6.2. Klient je povinen poskytovat společnosti Joviro pouze pravdivé,
        správné a úplné informace a zaregistrovat se výhradně pod svým jménem.
      </li>
      <li class="p-2">
        6.3. Klient se zavazuje komunikovat se systémem a společností Joviro a
        obecně užívat systém Bezmaklere eticky a v souladu s dobrými mravy.
        Systém Bezmaklere nesmí být používán k šíření nenávisti a nezákonného
        nebo nevhodného obsahu. V pochybnostech může společnost Joviro dle svého
        vlastního uvážení určit, zda je příslušný obsah nenávistný, nezákonný či
        nevhodný, a přijmout vhodná opatření.
      </li>
      <li class="p-2">6.4. Klient není oprávněn:</li>
      <li class="p-2 pl-4">
        vyjímat či používat jakékoli části Databáze či informace z ní získané za
        účelem vložení těchto údajů do jakékoli jiné databáze (takzvaně „Screen
        Scraping“)
      </li>
      <li class="p-2 pl-4">
        jakkoli rozmnožovat software, na němž je systém Bezmaklere založen, či
        pořídit jeho záložní kopii
      </li>
      <li class="p-2 pl-4">šířit tento software</li>
      <li class="p-2 pl-4">software vystavovat či sdělovat veřejnosti</li>
      <li class="p-2 pl-4">
        software překládat, přizpůsobovat, upravovat či jakkoli jinak měnit
      </li>
      <li class="p-2 pl-4">
        provádět zpětné inženýrství softwaru, dekompilovat ho, demontovat či se
        jakýmkoli jiným způsobem pokoušet získat zdrojový kód softwaru
      </li>
      <li class="p-2 pl-4">
        pokoušet se narušit bezpečnostní opatření softwaru nebo podnikat jiný
        druh útoku na software
      </li>
      <li class="p-2 pl-4">
        pokoušet se získat přístup k jakékoli části systému Bezmaklere, k níž jí
        společnost Joviro neudělila výslovný přístup
      </li>
      <li class="p-2 pl-4">
        přetěžovat systém Bezmaklere automatizovaným užíváním
      </li>
      <li class="p-2 pl-4">
        užívat systém Bezmaklere jakýmkoli způsobem, který je v rozporu s jeho
        účelem
      </li>
      <li class="p-2 pl-4">
        porušovat práva duševního vlastnictví společnosti Joviro podle těchto
        Podmínek
      </li>
      <li class="p-2">
        6.5. Klient je povinen upozornit společnost Joviro na jakékoli
        bezpečnostní nedostatky, o nichž se Klient při používání systému
        Bezmaklere dozví.
      </li>
      <li class="p-2">
        6.6. Aby se předešlo narušení Profilu Klienta v systému Bezmaklere, je
        Klient dále povinen:
      </li>
      <li class="p-2 pl-4">
        chránit veškerá používaná zařízení proti zneužití
      </li>
      <li class="p-2 pl-4">nastavit bezpečné přihlašovací údaje</li>
      <li class="p-2 pl-4">
        zabránit sdílení přihlašovacích údajů s třetími osobami
      </li>
      <li class="p-2 pl-4">chránit tyto přihlašovací údaje</li>
      <li class="p-2 pl-4">zabránit zneužití těchto přihlašovacích údajů</li>
      <li class="p-2">
        6.7. V případě závady v systému Bezmaklere může Klient kontaktovat
        společnost Joviro prostřednictvím kontaktů uvedených na jejích
        internetových stránkách. Společnost Joviro nebo její pověřený
        zaměstnanec vyřídí stížnost bez zbytečného odkladu. Klient bere na
        vědomí a souhlasí s tím, že společnosti Joviro může trvat přiměřeně
        dlouhou dobu, v závislosti na druhu služby, než závadu odborně zhodnotí.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        7. DŮVĚRNOST INFORMACÍ
      </div>
      <li class="p-2">
        7.1. Důvěrnými informacemi smluvní strany se rozumí jakékoli materiální,
        neveřejné informace o softwaru, obchodní činnosti či výrobě této smluvní
        strany, které jsou sděleny či zpřístupněny druhé smluvní straně, přímo
        či nepřímo, jakýmikoli komunikačními prostředky či pozorováním v
        souvislosti se službami poskytovanými v souladu s těmito Podmínkami.
        Důvěrné informace zahrnují také osobní údaje zpracovávané v souladu s
        těmito Podmínkami.
      </li>
      <li class="p-2">
        7.2. Pro účely tohoto článku se označuje smluvní strana poskytující své
        důvěrné informace druhé straně jako „sdělující strana“ a smluvní strana,
        která obdržela důvěrné informace sdělující strany, se označuje jako
        „přijímající strana“.
      </li>
      <li class="p-2">
        7.3. Přijímající strana se zavazuje zachovat důvěrnost veškerých
        důvěrných informací sdělující strany a důvěrnost bezpečnostních
        opatření, jejichž sdělení neoprávněné osobě by mohlo ohrozit bezpečnost
        důvěrných informací sdělující strany. Dodržování povinnosti zachování
        důvěrnosti znamená nezveřejňovat a nesdělovat důvěrné informace
        sdělující strany jakýmkoli třetím osobám a užívat důvěrné informace
        sdělující strany výlučně za účelem nezbytným k naplnění spolupráce
        smluvních stran podle těchto Podmínek.
      </li>
      <li class="p-2">
        7.4. Za porušení povinnosti zachování důvěrnosti se nepovažuje sdělení
        nebo užívaní důvěrných informací sdělující strany: i) s předchozím
        písemným souhlasem sdělující strany; ii) na základě povinnosti uložené
        právním předpisem nebo orgánem veřejné moci; iii) pokud přijímající
        strana získala důvěrné informace z jiných zdrojů než od sdělující strany
        a nikoli porušením povinnosti zachování důvěrnosti; iv) poradci,
        auditorovi, právnímu zástupci nebo jinému partnerovi přijímající strany,
        pokud je tato osoba na základě smlouvy či ze zákona vázána povinností
        zachovávat mlčenlivost ohledně důvěrných informací alespoň stejně
        přísnou, jako je povinnost mlčenlivosti stanovená v tomto článku nebo v)
        pokud je to nezbytné pro účely spolupráce mezi smluvními stranami.
      </li>
      <li class="p-2">
        7.5. Bez ohledu na jakékoli ustanovení těchto Podmínek je přijímající
        strana oprávněna sdělit či použít důvěrné informace sdělující strany
        pouze v nejmenším nutném rozsahu. V případě výjimky podle bodu ii)
        článku 9.4. těchto Podmínek a s výhradou ustanovení článku 12.4. těchto
        Podmínek je přijímající strana povinna informovat sdělující stranu o
        takovém sdělení či použití bez zbytečného odkladu, není-li to v rozporu
        se zákonem.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        8. OMEZENÍ ODPOVĚDNOSTI
      </div>
      <li class="p-2">
        8.1. Údaje o Klientovi v systému Bezmaklere pochází od samotného
        Klienta, a společnost Joviro proto nenese žádnou odpovědnost za
        pravdivost, úplnost nebo zákonnost těchto údajů. Společnost Joviro také
        nenese žádnou odpovědnost za zákonnost, úplnost či pravdivost jiných
        údajů, které Klient obdrží od poskytovatelů služeb, nebo komunikace mezi
        Klientem a třetí stranou jako poskytovatelem služeb.
      </li>
      <li class="p-2">
        8.2. Společnost Joviro nenese odpovědnost za služby poskytované třetími
        stranami, které si Klient objedná u těchto třetích stran v systému
        Bezmaklere. Společnost Joviro v žádném případě negarantuje úspěšný
        prodej nemovitosti, jelikož prodej záleží zejména na aktuálních tržních
        podmínkách a informacích.
      </li>
      <li class="p-2">
        8.3. Společnost Joviro nenese odpovědnost za bezchybnost a včasnost
        oznámení ze systému Bezmaklere a to, že Klient všechna oznámení obdrží;
        společnost Joviro se však vynasnaží dosáhnout úspěšného a včasného
        doručení veškerých oznámení.
      </li>
      <li class="p-2">
        8.4. Společnost Joviro poskytuje Klientovi na vyžádání vzorové smlouvy
        vypracované pro společnost Joviro Mgr. Zdeňkem Plesníkem, advokátem,
        ev.č. ČAK 15966. Jelikož jsou tyto smlouvy obecného charakteru,
        společnost Joviro neručí za jejich vhodnost při použití konkrétního
        obchodního případu Klienta, zejména v situacích, kdy na nemovitostech
        vázne zástavní či jiné právo financující banky, nebo v případě více
        vlastníků, nebo nákupu nemovitosti za použití hypotečního úvěru.
        Společnost Joviro v každém případě doporučuje kontaktovat výše uvedeného
        advokáta.
      </li>
      <li class="p-2">
        8.5. Společnost Joviro nenese odpovědnost za žádné nepříjemnosti nebo
        škody, k nimž případně dojde v důsledku poruch či chyb v systému
        Bezmaklere, jako je například mechanické selhání, technické selhání,
        selhání softwaru, aktualizace systému, porucha operačního systému, sítí
        či telekomunikačních systémů nebo z důvodu přerušení funkce systému
        Bezmaklere v důsledku výpadků elektrické energie nebo přerušení či
        rušení telekomunikačních služeb.
      </li>
      <li class="p-2">
        8.6. Společnost Joviro neodpovídá za škody, k nimž případně dojde v
        důsledku toho, že třetí osoba získá informace o Klientovi nebo o jeho
        nemovitosti ze systému Bezmaklere prostřednictvím nezákonného přístupu,
        zejména hackerstvím.
      </li>
      <li class="p-2">
        8.7. Klient, pokud zvolí některou z nadstandardních placených služeb,
        určí odpovídající způsob úhrady, poskytne informace o své platební kartě
        platební bráně pro potřeby úhrady ceny služeb systému Bezmaklere.
        Společnost Joviro nemá přístup k informacím o platební kartě nebo
        bankovním účtu Klienta a nenese odpovědnost za jakékoli narušení
        bezpečnosti údajů či únik informací o platební kartě nebo bankovním účtu
        Klienta.
      </li>
      <li class="p-2">
        8.8. Společnost Joviro nenese odpovědnost za události vyšší moci, které
        mohou způsobit nedostupnost služeb systému Bezmaklere, jako například
        rozhodnutí vlády, přírodní pohromy, epidemie, stávky (včetně plánovaných
        stávek) či výluky, vzpoury, nepokoje, sabotáž, terorismus a války nebo
        jiné obdobné události, kterým společnost Joviro nemůže běžným způsobem
        zabránit a nad kterými nemá kontrolu.
      </li>
      <li class="p-2">
        8.9. V době selhání či odstávky systému Bezmaklere může být systém pro
        Klienta dočasně nedostupný, aniž by tím Klienti vznikl nárok na
        jakoukoli náhradu. O takové odstávce může být Klient předem informován.
      </li>
      <li class="p-2">
        8.10. Klient bere na vědomí a souhlasí s omezením odpovědnosti uvedeným
        v tomto článku.
      </li>
      <li class="p-2">
        8.11. VZNIKNE-LI SPOLEČNOSTI JOVIRO JAKÁKOLI ODPOVĚDNOST ZA ÚJMU
        ZPŮSOBENOU KLIENTI, SMLUVNÍ STRANY SE DOHODLY A KLIENT VÝSLOVNĚ PŘIJÍMÁ,
        ŽE SPOLEČNOST JOVIRO NAHRADÍ ÚJMU POUZE DO CELKOVÉ VÝŠE 1.000,- KČ. TOTO
        OMEZENÍ SE NEVZTAHUJE NA SMRT, ÚJMU NA ZDRAVÍ ČI ÚMYSLNĚ ZPŮSOBENÉ
        ŠKODY.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        9. PRÁVA DUŠEVNÍHO VLASTNICTVÍ
      </div>
      <li class="p-2">
        9.1. Veškerý obsah systému Bezmaklere a internetových stránek
        společnosti Joviro, jako je jejich design, texty, grafika, obrázky,
        fotografie, informace, ochranné známky společnosti Joviro, ikony,
        počítačové programy, zdrojové kódy, Databáze a další předměty duševního
        vlastnictví, je ve výhradním vlastnictví společnosti Joviro a/nebo
        jejích partnerů a propojených osob a je chráněn autorským právem a
        právními předpisy na ochranu duševního vlastnictví. Neoprávněné užívání,
        včetně pořizování kopií, rozmnoženin a/nebo distribuce tohoto obsahu
        nebo jeho části, může být v rozporu se zákonem a je bez svolení
        společnosti Joviro zakázáno. Přijetím těchto Podmínek a užitím systému
        Bezmaklere společnost Joviro neposkytuje Uživateli ani Klientovi licenci
        k jakémukoli užívání duševního vlastnictví, kromě licence v rozsahu
        nezbytném k tomu, aby mohly tyto subjekty prostřednictvím systému
        Bezmaklere nabízet nabízet k prodeji svoje nemovitosti.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        10. PORUŠENÍ PODMÍNEK
      </div>
      <li class="p-2">
        10.1. Pokud Klient podstatným způsobem poruší tyto Podmínky a/nebo
        jakýmkoli způsobem zneužije systém Bezmaklere, vyhrazuje si společnost
        Joviro právo okamžitě a bez předchozího oznámení zablokovat přístup
        Klienti k systému Bezmaklere a dočasně či trvale uzamknout Profil
        Klienta a/nebo smazat Profil Klienta. Totéž platí v případě, že bude
        Klient jednat v rozporu se zákonem nebo dobrými mravy.
      </li>
      <li class="p-2">
        10.2. Podstatným porušením těchto Podmínek se rozumí zejména případy,
        pokud Klient:
      </li>
      <li class="p-2 pl-4">
        je v prodlení s jakoukoli platbou, kterou je společnost Joviro oprávněna
        účtovat na základě těchto Podmínek (zejména s platbou ceny) po dobu
        delší než 15 dnů
      </li>
      <li class="p-2 pl-4">
        odešle či bude jinak sdílet informace, které představují porušení
        zákona, Podmínek, nebo by mohly poškodit dobré jméno nebo pověst
        společnosti Joviro
      </li>
      <li class="p-2 pl-4">
        poruší své povinnosti stanovené v 6, 7 nebo 9 těchto Podmínek
      </li>
      <li class="p-2 pl-4">
        vytvoří Profil v systému Bezmaklere na základě neoprávněné registrace,
        zejména registrace neexistující či smyšlené osoby nebo registrace s
        použitím informací třetí osoby bez jejího svolení nebo
      </li>
      <li class="p-2 pl-4">
        šíří pomocí systému Bezmaklere nenávistný, nezákonný nebo nevhodný obsah
      </li>
      <li class="p-2">
        10.3. Společnost Joviro je oprávněna bez varování odstranit ze systému
        Bezmaklere veškerá nenávistná prohlášení a protiprávní nebo nevhodný
        obsah.
      </li>
      <li class="p-2">
        10.4. Společnost Joviro si vyhrazuje právo uvědomit příslušné úřady,
        pokud pojme podezření, že Klient jedná protiprávně. Toto se nepovažuje
        za porušení povinnosti zachování důvěrnosti podle článku 9 těchto
        Podmínek a není třeba na tuto skutečnost Klienta jakkoli upozorňovat.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        11. ZMĚNY PODMÍNEK
      </div>
      <li class="p-2">
        11.1. Společnost Joviro je oprávněna měnit tyto Podmínky, zejména s
        ohledem na změny právní úpravy v oblasti ochrany osobních údajů nebo
        pracovního práva, technologický pokrok, rozsahu služeb systému
        Bezmaklere nebo změny cen jejích dodavatelů, zejména co se týče rozsahu
        požadovaných údajů v Profilu, ceny služeb poskytovaných v souladu s
        těmito Podmínkami a platebních podmínek, poskytování licencí a/nebo práv
        duševního vlastnictví, přijímacího procesu nebo rozsahu služeb
        poskytovaných v souladu s těmito Podmínkami. Společnost Joviro oznámí
        Klienti změnu Podmínek na jeho e-mailovou adresu a/nebo oznámením v
        systému Bezmaklere a zpřístupněním nových Podmínek na internetových
        stránkách společnosti Joviro. Pokud Klient změnu Podmínek neodmítne do
        15 dnů ode dne, kdy obdržel oznámení e-mailem nebo prostřednictvím
        systému Bezmaklere, podle toho, které oznámení obdržel dříve, Klient
        přijímá nové Podmínky. Pokud v uvedené lhůtě Klient nové Podmínky
        odmítne, zakládá odmítnutí výpověď služeb systému Bezmaklere v 15denní
        výpovědní době, během které platí poslední Podmínky odsouhlasené oběma
        smluvními stranami.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        12. UKONČENÍ SLUŽEB
      </div>
      <li class="p-2">
        12.1. Klient má dle svého uvážení právo ukončit poskytování služeb
        systému Bezmaklere kdykoliv, a to vymazáním svého Profilu. Vymazání
        Profilu nemá vliv na povinnost uhradit Klientem již objednané služby.
      </li>
      <li class="p-2">
        12.2. Společnost Joviro má dle svého uvážení právo ukončit poskytování
        služeb s okamžitou účinností, a to od okamžiku, kdy byla výpověď
        doručena Klienti.
      </li>
      <li class="p-2">
        12.3. Při ukončení poskytování služeb systému Bezmaklere z jakéhokoli
        důvodu je Klient povinen uhradit společnosti Joviro cenu za služby
        objednané Klientem a poskytnuté třetími stranami.
      </li>
      <li class="p-2">
        12.4. Po ukončení poskytování služeb systému Bezmaklere bude Klientovi
        zablokován přístup k Profilu Klienta.
      </li>
      <li class="p-2">
        12.5. Ustanovení, jejichž účinek má trvat i po ukončení služeb systému
        Bezmaklere a této smlouvy, zůstanou účinná i po ukončení služeb systému
        Bezmaklere a této smlouvy. Zejména ustanovení článků 1, 2.3., 5, 8, 9,
        14.5., 13, 14 a 15 zůstávají účinná i po ukončení služeb systému
        Bezmaklere a této smlouvy. Povinnost zachování důvěrnosti důvěrných
        informací poskytnutých a přijatých před ukončením služeb systému
        Bezmaklere: i) pokud jsou tyto důvěrné informace obchodním tajemstvím,
        trvá, dokud nepřestanou být obchodním tajemstvím podle platných právních
        předpisů; ii) v případě ostatních důvěrných informací trvá po dobu 3 let
        od ukončení služeb systému Bezmaklere.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        13. SEZNAM POSKYTOVATELŮ SLUŽEB
      </div>
      <li class="p-2">13.1. Home staging</li>
      <li class="p-2 pl-4">
        Šárka Chrdlová, U Zastávky 427/1, Říčany, 251 01, IČ: 00919993
      </li>
      <li class="p-2">13.2. House tour</li>
      <li class="p-2 pl-4">
        Mgr. Radoslav Kašparec, Korunní 1208/74, Praha 10, 101 00, IČ: 08154457
      </li>
      <!-- <li class="p-2">
        13.3. Právní služby
      </li>
      <li class="p-2 pl-4">
        TODO: Získat údaje právníka
      </li> -->
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        14. ROZHODNÉ PRÁVO A ŘEŠENÍ SPORŮ
      </div>
      <li class="p-2 pl-4">
        14.1. Veškerá práva a povinnosti vyplývající z užívání systému
        Bezmaklere či v souvislosti s ním nebo těmito Podmínkami se řídí českým
        právem.
      </li>
      <li class="p-2 pl-4">
        14.2. Veškeré spory vzniklé v důsledku těchto Podmínek a/nebo užívání
        systému Bezmaklere či v souvislosti s nimi budou pravomocně rozhodnuty
        soudy České republiky, a to obecným soudem příslušným podle adresy sídla
        společnosti Joviro.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">15. JAZYK</div>
      <li class="p-2 pl-4">
        15.1. Tyto Podmínky byly vyhotoveny v českém jazyce. Veškeré povinnosti
        společnosti Joviro vůči Klienti v souvislosti s těmito Podmínkami a
        užíváním systému Bezmaklere budou plněny v českém jazyce.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">16. OZNAMOVÁNÍ</div>
      <li class="p-2 pl-4">
        16.1. Veškerá komunikace mezi smluvními stranami bude probíhat v českém
        jazyce, zejména elektronicky, prostřednictvím systému Bezmaklere,
        e-mailu nebo kontaktních údajů dostupných v sekci kontaktů na
        internetových stránkách společnosti Joviro nebo uvedených Profilu
        Klienta.
      </li>
      <li class="p-2 pl-4">
        16.2. Vyžaduje-li jakékoliv ujednání těchto Podmínek nebo ustanovení
        zákona od jednání písemnou formu, je tato forma dodržena, pokud
        společnost Joviro jednání učiní na e-mailovou adresu Klienta s prostým
        elektronickým podpisem nebo prostřednictvím systému Bezmaklere.
      </li>
      <div class="text-2xl text-left text-body-4 mt-4 pb-2">
        17. DOBA PLATNOSTI
      </div>
      <li class="p-2 pl-4">
        17.1. Tyto Podmínky vydala společnost Joviro a nabývají účinnosti dne
        1.6.2022
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: "TradeTerms",
};
</script>
